import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API } from '../../services/apiUrl.jsx';
import { Modal } from "../utility/modal.jsx";
import { InputForm } from "../utility/form.jsx";

const ShirtivalMbwInvoiceUpload = ({ currentUser }) => {

    const dateStringToTimestamp = (dateString) => {
        const [year, month, day] = dateString.split('-').map(Number);
        const dateObject = new Date(year, month - 1, day);
        return dateObject.getTime();
    };

    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [changedStates, setChangedStates] = useState(false);

    const [data, setData] = useState({
        pdf: null,
        excel: null,
        articleNumber: 'V001',
        supplierNumber: '70164',
        invoiceNumber: '',
        invoiceDate: dateStringToTimestamp(new Date().toISOString().split('T')[0]),
    });
    const [dataOriginal, setDataOriginal] = useState(data);
    const [errors, setErrors] = useState({});

    const saveChanges = () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setLoading(true);
            const formData = new FormData();
            formData.append('pdf', data.pdf);
            formData.append('excel', data.excel);
            formData.append('articleNumber', data.articleNumber);
            formData.append('supplierNumber', data.supplierNumber);
            formData.append('invoiceNumber', data.invoiceNumber);
            formData.append('invoiceDate', data.invoiceDate);
    
            formData.append('methode', 'CREATE');
            formData.append('currentUser', JSON.stringify(currentUser));
    
            axios.post(`${API}shirtival/mbwInvoiceUpload.php`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                // console.log(response)
                setChangedStates(false);
                setMessage('Suc-'+response.data);
                setData({
                    ...dataOriginal, 
                    pdf: null,
                    excel: null
                });
                setErrors({});
                setLoading(false);
            })
            .catch(error => {
                // console.log(error)
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                    error.message ||
                    error.toString();
                const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
                const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
                setMessage(errorMessage);
                setLoading(false);
            });
        }
    };

    const resetChanges = () => {
        setLoading(true)
        setData({
            ...dataOriginal, 
            pdf: null,
            excel: null
        });
        setErrors({});
        setChangedStates(false);
        setLoading(false)
    };

    useEffect(() => {
        const dataIsEqual = JSON.stringify(data) === JSON.stringify(dataOriginal);
        if (dataIsEqual) {
            setChangedStates(false);
        } else {
            setChangedStates(true);
        }
    }, [data]);

    const handleInputChange = (e, key, type) => {
        const value = type === 'file' ? e.target.files[0] : 
                      type === 'date' ? dateStringToTimestamp(e.target.value) : 
                      e.target.value;
        setData(prevState => ({ ...prevState, [key]: value }));
        setErrors({ ...errors, [key]: null });
    };

    const fields = [
        { key: 'pdf', type: 'file', placeholder: 'PDF-Datei (Rechnung)', icon: 'fa fa-file-pdf-o' },
        { key: 'excel', type: 'file', placeholder: 'Excel-Datei (Rechnung)', icon: 'fa fa-file-excel-o' },
        { key: 'articleNumber', type: 'text', placeholder: 'Artikelnummer', icon: 'fa fa-cube' },
        { key: 'supplierNumber', type: 'text', placeholder: 'Lieferantennummer', icon: 'fa fa-building-o' },
        { key: 'invoiceNumber', type: 'text', placeholder: 'Rechnungsnummer', icon: 'fa fa-money' },
        { key: 'invoiceDate', type: 'date', placeholder: 'Rechnungsdatum', icon: 'fa fa-calendar' },
    ];

    const validateFields = () => {
        const newErrors = {};
        const supplierNumberRegex = /^[789]\d{4}$/;
        const invoiceNumberRegex = /^\d{5}-\d{5}$/;
        const pdfRegex = /\.pdf$/i;
        const excelRegex = /\.xlsx$/i;

        if (!data.pdf || !data.pdf.name || !pdfRegex.test(data.pdf.name)) {
            newErrors.pdf = 'Lade eine PDF-Datei hoch';
        }
        if (!data.excel || !data.excel.name || !excelRegex.test(data.excel.name)) {
            newErrors.excel = 'Lade eine Excel-Datei hoch';
        }
        if (!data.articleNumber || data.articleNumber.trim() === '') {
            newErrors.articleNumber = 'Gib eine Artikelnummer an';
        }
        if (!data.supplierNumber || !supplierNumberRegex.test(data.supplierNumber)) {
            newErrors.supplierNumber = 'Gib eine gültige Lieferantennummer an';
        }
        if (!data.invoiceNumber || !invoiceNumberRegex.test(data.invoiceNumber)) {
            newErrors.invoiceNumber = 'Gib eine gültige Rechnungsnummer an';
        }
        if (!data.invoiceDate || data.invoiceDate === '' || data.invoiceDate === 0) {
            newErrors.invoiceDate = 'Gib ein gültiges Rechnungsdatum an';
        }

        return newErrors;
    };

    return (
        <div className="w3-container">
            <h2>Rechnungsupload MBW</h2>
            <React.Fragment>
                {message && (
                <Modal message={message} setMessage={setMessage}/>
                )}
                {loadingInitial ? (    
                    <div className="w3-section w3-display-container" style={{height: '200px'}}>
                        <div className="w3-display-middle">
                            <i className='fa fa-spinner fa-spin w3-margin-right w3-large'></i>
                        </div>    
                    </div> 
                ) : (
                    <React.Fragment>
                    <div className="w3-section w3-mobile w3-bar">
                        {changedStates && (
                        <React.Fragment>
                        <button 
                            className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`} 
                            style={{
                                pointerEvents: loading ? 'none' : 'auto'
                            }}
                            onClick={saveChanges}
                        >
                            {!loading ? (
                                <>
                                    <i className='fa fa-save w3-margin-right w3-small'></i>
                                </>
                            ) : (
                                <>
                                    <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                                </>
                            )}
                            Speichern
                        </button>
                        <button 
                            className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`} 
                            style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                marginRight: '8px'
                            }}
                            onClick={resetChanges}
                        >
                            {!loading ? (
                                <>
                                    <i className='fa fa-save w3-margin-right w3-small'></i>
                                </>
                            ) : (
                                <>
                                    <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                                </>
                            )}
                            Zurücksetzen
                        </button>
                        </React.Fragment>
                        )}     
                    </div>
                    <InputForm
                        data={data} 
                        fields={fields} 
                        errors={errors}
                        handleInputChange={handleInputChange} 
                    />
                    </React.Fragment>
                    )
                }
            </React.Fragment>
        </div>
    );
};

export { ShirtivalMbwInvoiceUpload };