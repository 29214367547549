import React, { useState, useEffect } from 'react';
import { TabButton } from '../utility/tabButton.jsx';
import { TimeManagementDashboard } from './timeManagement/timeManagementDashboard.jsx';
import { TimeManagement } from './timeManagement/timeManagement.jsx';
import { TimeConfiguration } from './timeManagement/configuration.jsx';
import { Absences } from './timeManagement/absences.jsx';
import axios from 'axios'; // Import axios for API requests

const TimeManagementTool = ({ currentUser }) => {
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1); // Default to current month
    const [activeComponent, setActiveComponent] = useState('dashboard');

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const [placeOfWork, setPlaceOfWork] = useState(null);
    const placeOfWorkOptions = [
        { id: 'BW', value: "Baden-Württemberg" },
        { id: 'BY', value: "Bayern" },
        { id: 'BE', value: "Berlin" },
        { id: 'BB', value: "Brandenburg" },
        { id: 'HB', value: "Bremen" },
        { id: 'HH', value: "Hamburg" },
        { id: 'HE', value: "Hessen" },
        { id: 'MV', value: "Mecklenburg-Vorpommern" },
        { id: 'NI', value: "Niedersachsen" },
        { id: 'NW', value: "Nordrhein-Westfalen" },
        { id: 'RP', value: "Rheinland Pfalz" },
        { id: 'SL', value: "Saarland" },
        { id: 'SN', value: "Sachsen" },
        { id: 'ST', value: "Sachen-Anhalt" },
        { id: 'SH', value: "Schleswig Holstein" },
        { id: 'TH', value: "Thüringen" }
    ];

    const fetchFeiertage = async (year, bundesland) => {
        try {
            const response = await axios.get(`https://feiertage-api.de/api/?`, {
                params: {
                    'jahr': year,
                    'nur_land': bundesland
                }
            }); 
            return Object.values(response.data).map(holiday => new Date(holiday.datum));
        } catch (error) {
            setMessage(error.response?.data?.detail || error.message || error);
            setLoading(false)
            return [];
        } finally {
            setLoading(false)
        }
    };

    const renderComponent = () => {
        switch (activeComponent) {
            case 'dashboard':
                return <TimeManagementDashboard currentUser={currentUser} year={year} setYear={setYear} />;
            case 'timeManagement':
                return <TimeManagement currentUser={currentUser} year={year} month={month} setYear={setYear} setMonth={setMonth} />;
            case 'absences':
                return <Absences currentUser={currentUser} year={year} placeOfWork={placeOfWork} setPlaceOfWork={setPlaceOfWork} fetchFeiertage={fetchFeiertage} />;
            case 'configuration':
                return <TimeConfiguration currentUser={currentUser} year={year} setYear={setYear} placeOfWork={placeOfWork} setPlaceOfWork={setPlaceOfWork} placeOfWorkOptions={placeOfWorkOptions} />;
            default:
                return null;
        }
    };

    return (
        <div className="w3-container">
            <h2>Zeit-Management</h2>
            <div className="w3-section">
                <div className="w3-bar w3-theme">
                    <TabButton onClick={() => setActiveComponent('dashboard')} isActive={activeComponent === 'dashboard'}>
                        Dashboard
                    </TabButton>
                    <TabButton onClick={() => setActiveComponent('timeManagement')} isActive={activeComponent === 'timeManagement'}>
                        Kalender
                    </TabButton>
                    <TabButton onClick={() => setActiveComponent('absences')} isActive={activeComponent === 'absences'}>
                        Urlaub & Krankheit
                    </TabButton>
                    <TabButton onClick={() => setActiveComponent('configuration')} isActive={activeComponent === 'configuration'}>
                        Konfiguration
                    </TabButton>
                </div>
            </div>
            {renderComponent()}
        </div>
    );
};

export { TimeManagementTool };
