import React, { useState, useEffect } from 'react';
import { Menu, MenuButton, MenuItems, MenuItem, Input } from "@headlessui/react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { Modal } from "../../utility/modal";

const ROWS_PER_PAGE = 10;

const TimeConfiguration = ({ currentUser, year, setYear, placeOfWork, setPlaceOfWork, placeOfWorkOptions }) => {
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [message, setMessage] = useState("");
    const [table, setTable] = useState([]);
    const [initialTable, setInitialTable] = useState([]);
    const [editingCell, setEditingCell] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        setLoadingInitial(true)

        const fetchTables = async () => {
            try {
                // // Fetch production places using axios
                // const response = await axios.get(`${weclappShirtivalAPI}/customAttributeDefinition`, {
                //     params: {
                //         ['attributeKey-in']:
                //         '[produktionsplanungOrt,produktionsplanungPresse,produktionsplanung,deadline,gesamtanzahlTextilien,produktionenErstellt,pickTextilien,pickFolien,druckAbgeschlossen,payedTime,productionPicksCompleted]',
                //         ['properties']: 'id,attributeKey,selectableValues.active,selectableValues.id,selectableValues.value',
                //     },
                //     headers: {
                //         AuthenticationToken: currentUser.api_key, // Ensure the user has a valid API key
                //     },
                // });   
                const testData = [
                    { hours: '40', year: 2025, place: 'SH' },
                    { hours: '40', year: 2024, place: 'SH' },
                    { hours: '35', year: 2023, place: 'SH' }
                ];
                const sortedData = [...testData].sort((a, b) => b.year - a.year);
                setTable(sortedData);
                setInitialTable(sortedData);
            } catch (error) {
                setMessage(error.response?.data?.detail || error.message);
            } finally {
                setLoadingInitial(false)
            }
        };
    
        fetchTables();
    }, []);

    const saveChanges = () => {
        setLoading(true);

        setInitialTable(table);
        setCurrentPage(0);
        setLoading(false);
    };

    const addNewRow = () => {
        setTable([{ hours: '', year: year, place: 1 }, ...table]);
    };

    const deleteRow = (index) => {
        setTable(table.filter((_, i) => i !== index));
    };

    const handleEdit = (index, field, value) => {
        const updatedTable = [...table];
        updatedTable[index] = { ...updatedTable[index], [field]: value };
        setTable(updatedTable);
    };

    const resetChanges = () => {
        setLoading(true);
        setTable(initialTable);
        setCurrentPage(0);
        setLoading(false);
    };

    useEffect(() => {
        setHasChanges(JSON.stringify(table) !== JSON.stringify(initialTable))
    }, [table,initialTable]);

    useEffect(() => {
        const matchingRow = table.find(row => row.year === year);
        if (matchingRow) {
            setPlaceOfWork(matchingRow.place);
        }
    }, [table, year]);
    

    const totalPages = Math.ceil(table.length / ROWS_PER_PAGE);
    const paginatedData = table.slice(currentPage * ROWS_PER_PAGE, (currentPage + 1) * ROWS_PER_PAGE);

    const Select = ({ value, onChange, options, standard, className }) => {
        // Find the selected option by ID to display its label
        const selectedOption = options.find(option => option.id === value);
      
        return (
            <Menu as="div" className="w-full">
                {({ open }) => (
                    <>
                        <MenuButton className={`${className}`}>
                            <span className='w3-left'>{selectedOption ? selectedOption.value : standard}</span>
                            <i className={`fa fa-angle-down transition-transform ${open ? 'rotate-180' : ''}`}></i>
                        </MenuButton>
                        <MenuItems className="absolute mt-1 w-fit bg-white border border-gray-300 rounded-md shadow-lg z-10">
                            {options.length > 0 ? (
                                options.map(option => (
                                    <MenuItem
                                        key={option.id}
                                        as="div"
                                        onClick={() => onChange(option.id)}
                                        className="p-2 hover:bg-gray-100 cursor-pointer"
                                    >
                                        {option.value}
                                    </MenuItem>
                                ))
                            ) : (
                                <div className="p-2 text-gray-500">Keine Optionen</div>
                            )}
                        </MenuItems>
                    </>
                )}
            </Menu>
        );
    };

    return (
        <DndProvider backend={HTML5Backend}>
            {/* Show Modal with the Error */}
            {message && <Modal message={message} setMessage={setMessage} />}
            {/* Show "Loading..." when loadingInitial is true */}
            {loadingInitial ? (
            <div className='w3-center'><i className='fa fa-spinner fa-spin w3-large w3-margin-top'></i></div>  // Show loading message when loadingInitial is true
            ) : (
                <React.Fragment>
                <div className="w3-section w3-mobile w3-bar">
                    <button className='w3-button w3-theme w3-bar-item' onClick={addNewRow}>
                        <i className='fa fa-plus w3-margin-right w3-small'></i> Neues Jahr
                    </button>
                    {hasChanges &&
                        <React.Fragment>
                        <button
                            className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`}
                            style={{ pointerEvents: loading ? 'none' : 'auto' }}
                            onClick={() => saveChanges()}
                        >
                            {!loading ? (
                                <>
                                    <i className='fa fa-save w3-margin-right w3-small'></i>
                                </>
                            ) : (
                                <>
                                    <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                                </>
                            )}
                            Speichern
                        </button>
                        <button
                            className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right`}
                            style={{ pointerEvents: loading ? 'none' : 'auto', marginRight: '8px' }}
                            onClick={() => resetChanges()}
                        >
                            {!loading ? (
                                <>
                                    <i className='fa fa-save w3-margin-right w3-small'></i>
                                </>
                            ) : (
                                <>
                                    <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                                </>
                            )}
                            Zurücksetzen
                        </button> 
                        </React.Fragment>
                    }                     
                </div>
                <div className="w3-section w3-mobile w3-bar">
                    <h3>Arbeitsstunden</h3>
                    <table className="w3-table-all w3-bordered w3-border" style={{ tableLayout: 'auto' }}>
                        <thead>
                            <tr key={'header'.frame} className='w3-light-grey'>
                                <th style={{ width: '30%' }}>Jahr</th>
                                <th style={{ width: '30%' }}>Stunden pro Woche</th>
                                <th style={{ width: '30%' }}>Arbeitsort</th>
                                <th style={{ width: '10%' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData.map((row, index) => (
                                <tr key={index}>
                                    <td
                                        onClick={() => setEditingCell({ index, field: "year" })}
                                        style={{cursor: "pointer "}}
                                    >
                                        {editingCell?.index === index && editingCell?.field === "year" ? (
                                            <Input
                                                type="number"
                                                className="w-full bg-transparent"
                                                value={row.year}
                                                onChange={(e) => handleEdit(index, "year", e.target.value)}
                                                onBlur={() => setEditingCell(null)}
                                                autoFocus
                                            />
                                        ) : (
                                            row.year || "—"
                                        )}
                                    </td>
                                    <td
                                        onClick={() => setEditingCell({ index, field: "hours" })}
                                        style={{cursor: "pointer "}}
                                    >
                                        {editingCell?.index === index && editingCell?.field === "hours" ? (
                                            <Input
                                                type="number"
                                                className="w-full bg-transparent"
                                                value={row.hours}
                                                onChange={(e) => handleEdit(index, "hours", e.target.value)}
                                                onBlur={() => setEditingCell(null)}
                                                autoFocus
                                            />
                                        ) : (
                                            row.hours || "—"
                                        )}
                                    </td>
                                    <td
                                        onClick={(e) => {
                                            if (!editingCell || editingCell.index !== index || editingCell.field !== "place") {
                                                setEditingCell({ index, field: "place" });
                                            }
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        {editingCell?.index === index && editingCell?.field === "place" ? (
                                            <Select 
                                                value={row.place} 
                                                onChange={(newValue) => {
                                                    handleEdit(index, "place", newValue);
                                                    setEditingCell(null); // Close after selecting
                                                }} 
                                                options={placeOfWorkOptions} 
                                                standard={"Wähle einen Arbeitsort aus."}
                                                className="w-full bg-transparent"
                                                onBlur={() => setEditingCell(null)}
                                            />
                                        ) : (
                                            placeOfWorkOptions.find(option => option.id === row.place)?.value || "—"
                                        )}
                                    </td>
                                    <td className="w3-center">
                                        <button className="" onClick={() => deleteRow(index)}>
                                            <i className='fa fa-trash w3-large w3-text-theme w3-hover-text-black'></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="w3-section w3-mobile">
                        <div className="w3-bar">
                            <button
                                className={`w3-button ${currentPage === 0 ? 'w3-disabled' : 'w3-theme'}`}
                                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
                                disabled={currentPage === 0}
                            >
                                <i className='fa fa-chevron-left'></i>
                            </button>
                            {[...Array(totalPages).keys()].map(pageNumber => (
                                <button
                                    key={pageNumber}
                                    className={`w3-button ${currentPage === pageNumber ? 'w3-theme' : ''}`}
                                    onClick={() => setCurrentPage(pageNumber)}
                                >
                                    {pageNumber + 1}
                                </button>
                            ))}
                            <button
                                className={`w3-button ${currentPage >= totalPages - 1 ? 'w3-disabled' : 'w3-theme'}`}
                                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))}
                                disabled={currentPage === totalPages}
                            >
                                <i className='fa fa-chevron-right'></i>
                            </button>
                        </div>
                    </div>
                </div>
                </React.Fragment>
            )}
        </DndProvider>
    );
};

export {TimeConfiguration};