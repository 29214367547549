import React, { useState, useEffect, useRef } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react"; // Updated imports
import axios from 'axios'; // Import axios for API requests
import { API, weclappShirtivalAPI } from '../../../services/apiUrl';
import { Modal } from "../../utility/modal";

const ItemType = "PRODUCTION_ORDER";

const ShirtivalProductionPlan = ( {currentUser} ) => {
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [productionPlaces, setProductionPlaces] = useState([]);
  const [productionPresse, setProductionPresse] = useState([]);
  const [customAttributes, setCustomAttributes] = useState({});
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedPresse, setSelectedPresse] = useState(null);
  const [calendarData, setCalendarData] = useState({});
  const [unplannedOrders, setUnplannedOrders] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [customerData, setCustomerData] = useState({});
  const [salesChannel, setSalesChannel] = useState({});
  const [showWeekend, setShowWeekend] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [changesMade, setChangesMade] = useState(false);
  const [config, setConfig] = useState({});
  const [presseIsActive, setPresseIsActive] = useState(true);
  const divRef = useRef(null);  // Create a ref for the target div
  const [height, setHeight] = useState(0);  // State to store the height
  const [highestHeight, setHighestHeight] = useState(0);

  const fetchProductionPlaces = async () => {
    try {
      // Fetch production places using axios
      const response = await axios.get(`${weclappShirtivalAPI}/customAttributeDefinition`, {
        params: {
          ['attributeKey-in']:
            '[produktionsplanungOrt,produktionsplanungPresse,produktionsplanung,deadline,deadlineKunde,druckFreigabe,gesamtanzahlTextilien,produktionenErstellt,pickTextilien,pickFolien,druckAbgeschlossen,payedTime,productionPicksCompleted,sonderdruck,aermeldruckRechts,aermeldruckLinks,druckHinten,druckVorne]',
          ['properties']: 'id,attributeKey,selectableValues.active,selectableValues.id,selectableValues.value',
        },
        headers: {
          AuthenticationToken: currentUser.api_key, // Ensure the user has a valid API key
        },
      });

      const newAttributes = {};
      response?.data?.result?.forEach(item => {
        newAttributes[item.attributeKey] = item.id;
      });
      setCustomAttributes(newAttributes);

      // Ensure that the data structure is as expected
      const places = response?.data?.result?.find(item => item.attributeKey === 'produktionsplanungOrt');
      if (places && Array.isArray(places.selectableValues)) {
        // Filter active places where 'active' is not false
        const activePlaces = places.selectableValues.filter(value => value?.active !== false);
        setProductionPlaces(activePlaces);
      } else {
        console.error("Unexpected structure in API response", response);
      }
      // Ensure that the data structure is as expected
      const pressen = response?.data?.result?.find(item => item.attributeKey === 'produktionsplanungPresse');
      if (pressen && Array.isArray(pressen.selectableValues)) {
        // Filter active places where 'active' is not false
        // const activePressen = pressen.selectableValues.filter(value => value?.active !== false);
        const activePressen = pressen.selectableValues;
        // Manually add the "keine Auswahl" option at the beginning (use push() for the end)
        activePressen.unshift({ "id": null, "active": true, "value": "keine Auswahl" });
        activePressen.push({ "id": "all", "active": false, "value": "Gesamtauslastung" });
        setProductionPresse(activePressen);
      } else {
        console.error("Unexpected structure in API response", response);
      }
    } catch (error) {
      setMessage(error.response?.data?.detail || error.message);
    } 
  };
  const fetchSalesChannels = async () => {
    try {
      // Fetch production places using axios
      const response = await axios.get(`${weclappShirtivalAPI}/salesChannel/activeSalesChannels`, {
        headers: {
          AuthenticationToken: currentUser.api_key, // Ensure the user has a valid API key
        },
      });
      setSalesChannel(response?.data?.result);
    } catch (error) {
      setMessage(error.response?.data?.detail || error.message);
    }
  };
  const fetchConfig = async () => {
    try {
      const response = await axios.post(`${API}/shirtival/productionPlaningConfig.php`, {
        methode: 'GET'
      });
      setConfig(response?.data || {});
    } catch (error) {
      setMessage(error.response?.data?.detail || error.message);
    }
  };
  const fetchUnplannedOrders = async () => {
    if (Object.keys(customAttributes).length === 0) return; // Wait until customAttributes is populated
    setLoadingInitial(true);
  
    try {
      let currentPage = 1;
      let hasMoreData = true;
  
      // Temporary storage to accumulate data
      let allUnplannedOrders = {};
      let allCustomerData = {};
      let allOriginalData = {};
  
      while (hasMoreData) {
        const response = await axios.get(`${weclappShirtivalAPI}/salesOrder`, {
          params: { 
            page: currentPage,
            pageSize: 100,
            [`or-customAttribute${customAttributes.produktionsplanung}-null`]: '',
            [`or-customAttribute${customAttributes.produktionsplanungOrt}.value-null`]: '',
            [`customAttribute${customAttributes.produktionenErstellt}-eq`]: true,
            'shipped-eq': false,
            'status-eq': 'ORDER_CONFIRMATION_PRINTED',
            [`customAttribute${customAttributes.payedTime}-notnull`]: '',
            [`customAttribute${customAttributes.druckFreigabe}-eq`]: true,
            'properties': 'id,orderNumber,customAttributes,customerId,salesChannel,shipped',
            'includeReferencedEntities': 'customerId'
          },
          headers: {
            AuthenticationToken: currentUser.api_key,
          },
        });
  
        const unplanned = response?.data?.result || [];
        const party = response?.data?.referencedEntities?.party || {};
  
        if (unplanned.length === 0) {
          hasMoreData = false;
        } else {
          // Accumulate unplanned orders
          Object.values(unplanned).forEach(item => {
            allUnplannedOrders[item.id] = item;
          });
  
          // Accumulate customer data
          Object.values(party).forEach(item => {
            allCustomerData[item.id] = item;
          });
  
          // Accumulate original data
          Object.values(unplanned).forEach(item => {
            allOriginalData[item.id] = item;
          });
  
          currentPage++;
        }
      }
  
      // **Set state only once after the loop**
      setCustomerData(prevData => ({
        ...prevData,
        ...allCustomerData,
      }));
  
      setUnplannedOrders(prevData => ({
        ...prevData,
        ...allUnplannedOrders,
      }));
  
      setOriginalData(prevData => ({
        calendar: {
          ...prevData.calendar,
        },
        unplanned: {
          ...prevData.unplanned,
          ...allOriginalData,
        },
      }));
  
    } catch (error) {
      setMessage(error.response?.data?.detail || error.message);
    } finally {
      setLoadingInitial(false);
    }
  };
  const fetchCalendarData = async () => {
    if (!selectedPlace) return;
    setLoading(true);
    
    try {
      const startOfWeek = new Date(startDate);
      startOfWeek.setDate(startDate.getDate() - (startDate.getDay() === 0 ? 6 : startDate.getDay() - 1)); // Adjust for Sunday case
  
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
  
      let currentPage = 1;
      let hasMoreData = true;
  
      // Temporary storage to accumulate data
      let allCalendarData = {};
      let allCustomerData = {};
      let allOriginalData = {};
  
      while (hasMoreData) {
        const response = await axios.get(`${weclappShirtivalAPI}/salesOrder`, {
          params: { 
            page: currentPage,
            pageSize: 100,
            [`customAttribute${customAttributes.produktionsplanung}-ge`]: startOfWeek.getTime(),
            [`customAttribute${customAttributes.produktionsplanung}-le`]: endOfWeek.getTime(),
            [`customAttribute${customAttributes.produktionsplanungOrt}.id-eq`]: selectedPlace,
            [`customAttribute${customAttributes.produktionenErstellt}-eq`]: true,
            'status-ne': 'CANCELLED',
            'status-ne': 'MANUALLY_CLOSED',
            'properties': 'id,orderNumber,customAttributes,customerId,salesChannel,shipped',
            'includeReferencedEntities': 'customerId'
          },
          headers: {
            AuthenticationToken: currentUser.api_key,
          },
        });
  
        const calendar = response?.data?.result || [];
        const customer = response?.data?.referencedEntities?.party || {};
  
        if (calendar.length === 0) {
          hasMoreData = false;
        } else {
          // Accumulate calendar data
          Object.values(calendar).forEach(item => {
            allCalendarData[item.id] = item;
          });
  
          // Accumulate customer data
          Object.values(customer).forEach(item => {
            allCustomerData[item.id] = item;
          });
  
          // Accumulate original data
          Object.values(calendar).forEach(item => {
            allOriginalData[item.id] = item;
          });
  
          currentPage++;
        }
      }
  
      // **Set state only once after the loop**
      setCalendarData(prevData => ({
        ...prevData,
        ...allCalendarData,
      }));
  
      setCustomerData(prevData => ({
        ...prevData,
        ...allCustomerData,
      }));
  
      setOriginalData(prevData => ({
        calendar: {
          ...prevData.calendar,
          ...allOriginalData,
        },
        unplanned: {
          ...prevData.unplanned,
        },
      }));
  
    } catch (error) {
      setMessage(error.response?.data?.detail || error.message);
    } finally {
      setLoading(false);
    }
  };
  const moveOrder = (order, targetDate, selectedPlace, selectedPresse) => {
    const updatedOrder = {
      ...order,
      customAttributes: order.customAttributes.map((attr) => {
        if (attr.attributeDefinitionId === customAttributes.produktionsplanung) {
          return { ...attr, dateValue: targetDate ? new Date(targetDate).getTime() : null };
        }
        if (attr.attributeDefinitionId === customAttributes.produktionsplanungOrt) {
          return { ...attr, selectedValueId: selectedPlace ? selectedPlace : null };
        }
        if (attr.attributeDefinitionId === customAttributes.produktionsplanungPresse) {
          return { ...attr, selectedValueId: selectedPresse ? selectedPresse : null };
        }
        return attr;
      }),
    };
    // If targetDate is provided, update calendarData
    if (targetDate) {
      // Update the calendarData by checking if the order ID exists for the targetDate
      setCalendarData(prev => ({
        ...prev,
        [updatedOrder.id]: updatedOrder
      }));
      // Remove from unplannedOrders if it's present
      setUnplannedOrders((prev) => {
        const existingOrders = { ...prev };
        // Filter out the order with the same id as the one to be removed
        Object.keys(existingOrders).forEach((unplannedOrderKey) => {
          if (existingOrders[unplannedOrderKey].id === order.id) {
            delete existingOrders[unplannedOrderKey]; // Remove the order by key
          }
        });
        return existingOrders;
      });
    } else {
    // If no targetDate is provided, remove from calendarData and add to unplannedOrders
      setCalendarData((prev) => {
        const existingOrders = { ...prev };
        // Filter out the order with the same id as the one to be removed
        Object.keys(existingOrders).forEach((unplannedOrderKey) => {
          if (existingOrders[unplannedOrderKey].id === order.id) {
            delete existingOrders[unplannedOrderKey]; // Remove the order by key
          }
        });
        return existingOrders;
      });
      setUnplannedOrders(prev => ({
        ...prev,
        [updatedOrder.id]: updatedOrder
      }));
    }
  };
  const resetChanges = () => {
    setCalendarData(originalData.calendar);
    setUnplannedOrders(originalData.unplanned);
  };
  const saveChanges = async () => {
    setLoading(true);

    const sortedCalendarData = Object.values(calendarData).sort((a, b) => a.id.localeCompare(b.id));
    const sortedOriginalCalendar = Object.values(originalData.calendar).sort((a, b) => a.id.localeCompare(b.id));
    const sortedUnplannedOrders = Object.values(unplannedOrders).sort((a, b) => a.id.localeCompare(b.id));
    const sortedOriginalUnplannedOrders = Object.values(originalData.unplanned).sort((a, b) => a.id.localeCompare(b.id));

    // Reusable function to find changed entries in any dataset
    const findChangedEntries = (newData, originalData) => {
      // Deep equality function
      const deepEqual = (obj1, obj2) => {
        if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {
            return obj1 === obj2;
        }

        const keys1 = Object.keys(obj1).sort();
        const keys2 = Object.keys(obj2).sort();
        if (keys1.length !== keys2.length || !keys1.every((key, i) => key === keys2[i])) return false;

        return keys1.every(key => deepEqual(obj1[key], obj2[key]));
      };
      // Compare `customAttributes` by `attributeDefinitionId`
      const compareCustomAttributes = (newAttributes, oldAttributes) => {
          const newMap = new Map(newAttributes.map(attr => [attr.attributeDefinitionId, attr]));
          const oldMap = new Map(oldAttributes.map(attr => [attr.attributeDefinitionId, attr]));

          const differences = [];

          for (const [id, newAttr] of newMap) {
              const oldAttr = oldMap.get(id);
              if (!oldAttr || !deepEqual(newAttr, oldAttr)) {
                  differences.push({ attributeDefinitionId: id, new: newAttr, old: oldAttr || null });
              }
          }

          for (const [id, oldAttr] of oldMap) {
              if (!newMap.has(id)) {
                  differences.push({ attributeDefinitionId: id, new: null, old: oldAttr });
              }
          }

          return differences.length > 0 ? differences : null;
      };
      const originalDataMap = new Map(originalData.map(item => [item.id, item]));
      return newData.filter(newItem => {
          const originalItem = originalDataMap.get(newItem.id);
          if (!originalItem) return true; // New entry

          const differences = {};
          for (const key in newItem) {
              if (!deepEqual(newItem[key], originalItem[key])) {
                  differences[key] = { new: newItem[key], old: originalItem[key] };
              }
          }
          // Check customAttributes separately
          const customAttrDifferences = compareCustomAttributes(newItem.customAttributes || [], originalItem.customAttributes || []);
          if (customAttrDifferences) {
              differences.customAttributes = customAttrDifferences;
          }
          return Object.keys(differences).length > 0;
      });
    };

    const changedCalendarEntries = findChangedEntries(sortedCalendarData, sortedOriginalCalendar);
    const changedUnplannedEntries = findChangedEntries(sortedUnplannedOrders, sortedOriginalUnplannedOrders);

    // Extracted function for updating sales orders and modifying state
    const updateSalesOrder = async (entries) => {
      return Promise.all(
        entries.map(async (entry) => {
          try {
            // Filter the customAttributes based on the specific attributeDefinitionIds
            const filteredCustomAttributes = entry.customAttributes
              .filter(attr => 
                [customAttributes.produktionsplanung, customAttributes.produktionsplanungOrt, customAttributes.produktionsplanungPresse]
                .includes(attr.attributeDefinitionId)
              )
              .map(attr => ({
                attributeDefinitionId: attr.attributeDefinitionId,
                [Object.keys(attr).find(key => key !== 'attributeDefinitionId')]: attr[Object.keys(attr).find(key => key !== 'attributeDefinitionId')]
              }));

            // Send update request
            const response = await axios.put(
              `${weclappShirtivalAPI}/salesOrder/id/${entry.id}`,
              { customAttributes: filteredCustomAttributes },
              {
                params: {
                  "ignoreMissingProperties": true,
                  // "dryRun": true // Live remove!
                },
                headers: {
                  AuthenticationToken: currentUser.api_key
                }
              }
            );
          } catch (error) {
            throw error; // Ensure error is caught in Promise.all
          }
        })
      );
    };

    // Run both updates and handle messages accordingly
    try {
      const updates = [];

      if (changedCalendarEntries.length !== 0) {
        updates.push(updateSalesOrder(changedCalendarEntries));
      }
      if (changedUnplannedEntries.length !== 0) {
        updates.push(updateSalesOrder(changedUnplannedEntries));
      }

      if (updates.length > 0) {
        await Promise.all(updates);
        setOriginalData({
          calendar: 
            {
              ...Object.values(sortedCalendarData).reduce((acc, item) => {
              acc[item.id] = item;
              return acc;
              }, {})
            },
          unplanned: 
            {
              ...Object.values(sortedUnplannedOrders).reduce((acc, item) => {
              acc[item.id] = item;
              return acc;
              }, {})
            }
        });  
        setMessage('Suc-Die Planung wurde angepasst.');
      }
    } catch (error) {
      resetChanges();
      setMessage(error.response?.data?.detail || error.message);
    } finally {
      setLoading(false);
    }
  };
  const goToPreviousWeek = () => {
  setStartDate((prev) => new Date(prev.setDate(prev.getDate() - 7)));
  };
  const goToNextWeek = () => {
    setStartDate((prev) => new Date(prev.setDate(prev.getDate() + 7)));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [places, sales, config] = await Promise.allSettled([
          fetchProductionPlaces(),
          fetchSalesChannels(),
          fetchConfig()
        ]);
        // Handle individual failures
        if (places.status === "rejected") {
          setMessage(places.reason.response?.data?.detail || places.reason.message);
        }
        if (sales.status === "rejected") {
          setMessage(sales.reason.response?.data?.detail || sales.reason.message);
        }
        if (config.status === "rejected") {
          setMessage(config.reason.response?.data?.detail || config.reason.message);
        }
      } catch (error) {
        setMessage(error.response?.data?.detail || error.message);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    fetchUnplannedOrders();
  }, [customAttributes]); 
  useEffect(() => {
    fetchCalendarData();
  }, [selectedPlace, startDate]);  
  useEffect(() => {
    setSelectedPresse(null)
    setPresseIsActive(true)
  }, [selectedPlace]);
  useEffect(() => {
    const isSelectedPresseActive = (selectedPresse, productionPresse) => {
      const presse = productionPresse.find(p => p.id === selectedPresse);
      return presse ? presse.active : false; // Returns false if not found
    };
    setPresseIsActive(isSelectedPresseActive(selectedPresse, productionPresse));
  }, [selectedPresse]);
  useEffect(() => {
    // Ensure unplannedOrders and originalData.unplanned are not null or undefined before proceeding
    if (unplannedOrders && originalData.unplanned) {
      // Convert unplannedOrders and originalData.unplanned to arrays and sort by id
      const sortedUnplannedOrders = Object.values(unplannedOrders).sort((a, b) => a.id.localeCompare(b.id));
      const sortedOriginalUnplanned = Object.values(originalData.unplanned).sort((a, b) => a.id.localeCompare(b.id));  
      // Compare the sorted arrays
      const hasUnplannedChanged = JSON.stringify(sortedUnplannedOrders) !== JSON.stringify(sortedOriginalUnplanned);  
      setChangesMade(hasUnplannedChanged);
    }
  }, [unplannedOrders, originalData.unplanned]);
  useEffect(() => {
    // Ensure unplannedOrders and originalData.unplanned are not null or undefined before proceeding
    if (calendarData && originalData.calendar) {
      // Convert unplannedOrders and originalData.unplanned to arrays and sort by id
      const sortedCalendarData = Object.values(calendarData).sort((a, b) => a.id.localeCompare(b.id));
      const sortedOriginalCalendar = Object.values(originalData.calendar).sort((a, b) => a.id.localeCompare(b.id));  
      // Compare the sorted arrays
      const hasUnplannedChanged = JSON.stringify(sortedCalendarData) !== JSON.stringify(sortedOriginalCalendar);  
      setChangesMade(hasUnplannedChanged);
    }
  }, [calendarData, originalData.calendar]);
  useEffect(() => {
    // Only proceed if the div is rendered
    if (divRef.current) {
      // Create a ResizeObserver
      const resizeObserver = new ResizeObserver((entries) => {
        // Get the height of the observed element
        const newHeight = entries[0].contentRect.height;
        setHeight(newHeight);  // Update the state with the new height
      });
      // Start observing the target element
      resizeObserver.observe(divRef.current);
      // Cleanup the observer when the component is unmounted
      return () => {
        if (divRef.current) {
          resizeObserver.unobserve(divRef.current);
        }
      };
    }
  }, [loadingInitial]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="w3-section">
        {/* Show Modal with the Error */}
        {message && (
          <Modal 
            message={message}
            setMessage={setMessage}
          />
        )}
        {/* Show "Loading..." when loadingInitial is true */}
        {loadingInitial ? (
          <div><i className='fa fa-spinner fa-spin w3-large w3-margin-top'></i></div>  // Show loading message when loadingInitial is true
        ) : (
          <>
            {/* Show the rest of the UI when loadingInitial is false */}
            <div className="w3-section w3-mobile w3-bar">
                <Select 
                    value={selectedPlace} 
                    onChange={setSelectedPlace} 
                    options={productionPlaces} 
                    standard={"Wähle einen Produktionsort aus"}
                />
                {selectedPlace === '3935918' ? (
                  <Select 
                  value={selectedPresse} 
                  onChange={setSelectedPresse} 
                  options={productionPresse} 
                  standard={"Wähle eine Presse aus"}
                  />
                ) : null}
                {selectedPlace ? (
                  selectedPlace !== "3935918" || selectedPresse ? (
                    <Menu as="div" className="">
                      <MenuButton
                        onClick={() => setShowWeekend((prev) => !prev)}
                        className="w3-button w3-theme w3-bar-item"
                        style={{marginLeft: '8px'}}
                      >
                        <i className={`fa ${showWeekend ? 'fa-eye-slash' : 'fa-eye'} w3-margin-right`}></i> Wochenende
                      </MenuButton>
                    </Menu>
                  ) : null ) : null }

                {selectedPlace ? (
                  selectedPlace !== "3935918" || selectedPresse ? (
                    changesMade ? (
                      <>
                      <Menu as="div" className="">
                      <MenuButton
                        onClick={resetChanges}
                        className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`}
                        style={{pointerEvents: loading ? 'none' : 'auto'}}
                      >
                        {loading ? <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i> : <i className='fa fa-repeat w3-margin-right w3-small'></i>} Zurücksetzen
                      </MenuButton>
                    </Menu>
                    <Menu as="div" className="">
                      <MenuButton
                        onClick={saveChanges}
                        className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`}
                        style={{pointerEvents: loading ? 'none' : 'auto', marginRight: '8px'}}
                      >
                        {loading ? <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i> : <i className='fa fa-save w3-margin-right w3-small'></i>} Speichern
                      </MenuButton>
                    </Menu>
                    </>
                    ) : null ) : null ) : null}
            </div>
            <div className="w3-section w3-mobile">
              {selectedPlace ? (
                selectedPlace !== "3935918" || selectedPresse ? (
                  <div className="w3-section w3-mobile">
                    <button
                        onClick={goToPreviousWeek}
                        className="w3-button w3-theme"
                    >
                        <i className="fa fa-arrow-left w3-margin-right"></i> Vorherige Woche
                    </button>

                    <button
                        onClick={goToNextWeek}
                        className="w3-button w3-theme w3-right"
                    >
                        Nächste Woche <i className="fa fa-arrow-right w3-margin-left"></i>
                    </button>
                </div>
                ) : null 
              ) : null}
              <div className="w3-section w3-mobile">
                  <div className="flex gap-2 mb-4" ref={divRef}>
                    {selectedPresse === null || selectedPresse !== "all" ? (
                    <UnplannedOrders orders={unplannedOrders} moveOrder={moveOrder} customerData={customerData} salesChannel={salesChannel} customAttributes={customAttributes} selectedPresse={selectedPresse} presseIsActive={presseIsActive} config={config} height={height} />
                    ) : null}
                    {loading ? (
                      <div><i className='fa fa-spinner fa-spin w3-large w3-margin-top'></i></div>  // Show loading message for calendar data when loading is true
                    ) : selectedPlace ? (
                        selectedPlace !== "3935918" || selectedPresse ? (
                          <CalendarView startDate={startDate} data={calendarData} moveOrder={moveOrder} showWeekend={showWeekend} selectedPlace={selectedPlace} customAttributes={customAttributes} customerData={customerData} salesChannel={salesChannel} selectedPresse={selectedPresse} presseIsActive={presseIsActive} currentUser={currentUser} setMessage={setMessage} config={config} height={height} />
                        ) : (
                          <div className="w-5/6 text-gray-500 w3-small w3-center">Wähle eine Presse aus.</div>
                        )
                    ) : null }
                  </div>
              </div>
            </div>
          </>
        )}
      </div>
    </DndProvider>
  );
};

// Helper function to get the Monday of the current week
const getMonday = (date) => {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - (day === 0 ? 6 : day - 1); // Adjust if Sunday (0)
    return new Date(d.setDate(diff));
};

const UnplannedOrders = ({ orders, moveOrder, customerData, salesChannel, customAttributes, selectedPlace, selectedPresse, presseIsActive, config, height }) => {
  const [, drop] = useDrop({
    accept: ItemType, // Define the accepted item type for the drop
    drop: (item) => {
      // Move the order from calendar to unplanned orders
      if (presseIsActive) {
        moveOrder(item.order, null, null, null);
      }
    },
  });

  const sortOrders = (orders, customAttributes) => {
    const getCustomAttributeValue = (order, attributeId) => {
      const attribute = order.customAttributes.find(attr => attr.attributeDefinitionId === attributeId);
      if (!attribute) return null;
  
      // Check for the type of value (numberValue, stringValue, booleanValue, dateValue, etc.)
      if (attribute.numberValue) return attribute.numberValue;
      if (attribute.stringValue) return attribute.stringValue;
      if (attribute.booleanValue !== undefined) return attribute.booleanValue;
      if (attribute.dateValue) return attribute.dateValue;
      return null;
    }; 
    return orders.sort((a, b) => {
      const isShippedA = a.shipped === true;
      const isShippedB = b.shipped === true;
  
      const isProductionFinishedA = getCustomAttributeValue(a, customAttributes.druckAbgeschlossen) === true;
      const isProductionFinishedB = getCustomAttributeValue(b, customAttributes.druckAbgeschlossen) === true;
  
      const picksCompleteA = getCustomAttributeValue(a, customAttributes.productionPicksCompleted) === true;
      const picksCompleteB = getCustomAttributeValue(b, customAttributes.productionPicksCompleted) === true;
  
      const pickFolienA = getCustomAttributeValue(a, customAttributes.pickFolien) === true;
      const pickFolienB = getCustomAttributeValue(b, customAttributes.pickFolien) === true;
  
      const pickTextilienA = getCustomAttributeValue(a, customAttributes.pickTextilien) === true;
      const pickTextilienB = getCustomAttributeValue(b, customAttributes.pickTextilien) === true;
  
      const deadlineA = getCustomAttributeValue(a, customAttributes.deadline);
      const deadlineB = getCustomAttributeValue(b, customAttributes.deadline);

      // If deadline exists, sort by the deadline timestamp
      const compareByDeadline = (deadlineA && deadlineB) ? deadlineA - deadlineB : 0;

      // Assign priority scores (lower means higher priority)
      const getPriority = (isShipped, isFinished, picksComplete, pickFolien, pickTextilien) => {
        if (isShipped) return 5; // Knallgrün (shipped) → Last
        if (isFinished) return 4; // Grau (production finished) → Second last
        if (picksComplete) return 3; // Hellgrün (both available)
        if (pickFolien || pickTextilien) return 2; // Gelb (partial available)
        return 1; // Rot (nothing available) → Top priority
      };
  
      const priorityA = getPriority(isShippedA, isProductionFinishedA, picksCompleteA, pickFolienA, pickTextilienA);
      const priorityB = getPriority(isShippedB, isProductionFinishedB, picksCompleteB, pickFolienB, pickTextilienB);
  
      // If priority is the same, compare by deadline, otherwise use priority comparison
      return compareByDeadline !== 0 ? compareByDeadline : priorityA - priorityB;
    });
  };

  const sortedOrders = sortOrders(Object.values(orders), customAttributes);

  const [sliceValue, setSliceValue] = useState(7);  // State to store the height
  useEffect(() => {
    if (height > 300) {
      const newSliceValue = Math.floor(height / 90);
      setSliceValue(newSliceValue);
    }
  }, [height]);

  useEffect(() => {
    console.log("height",height)
  }, [height]);

  return (
    <div ref={drop} className="w-1/6 w3-border" style={{height: height}}>
      {sortedOrders.length > 0 ? ( // Check if orders is not an empty object
        sortedOrders
        .slice(0, sliceValue)
        .map((order) => (
          <DraggableOrder key={order.id} order={order} moveOrder={() => moveOrder(order, null, selectedPlace, selectedPresse)} customerData={customerData} salesChannel={salesChannel} customAttributes={customAttributes} presseIsActive={presseIsActive} config={config} />
        ))
      ) : (
        <p className="text-gray-500 w3-small w3-center">Es wurden alle Aufträge geplant</p> // Display message when no orders
      )}
    </div>
  );
};

const CalendarView = ({ startDate, data, moveOrder, showWeekend, selectedPlace, customAttributes, customerData, salesChannel, selectedPresse, presseIsActive, currentUser, setMessage, config, height }) => {
    const monday = getMonday(startDate); // Ensure we always start on Monday
    const days = [];
  
    for (let i = 0; i < 7; i++) {
      const date = new Date(monday);
      date.setDate(monday.getDate() + i);
      if (!showWeekend && (date.getDay() === 0 || date.getDay() === 6)) continue;
      days.push(new Date(date));
    }

    // Get Machine ID
    const [machineId, setMachineId] = useState(0);
    useEffect(() => {
      if (selectedPlace != "3935918") return;
      const fetchMachineId = async () => {
        try {
          const response = await axios.post(`${API}/shirtival/productionPlaningMachineId.php`, {
            methode: 'GET',
            machineId: selectedPresse,
            currentUser
          });
          setMachineId(response?.data || 0);
        } catch (error) {
          setMessage(error.response?.data?.detail || error.message);
          console.error("Error fetching calendar data:", error);
        } 
      };
      fetchMachineId();
    }, [selectedPresse]);

    return (
      <div className={`grid ${showWeekend ? "grid-cols-7" : "grid-cols-5"} gap-2 ${selectedPresse === "all" ? "w-full" : "w-5/6"} transition-all duration-300 ease-in-out`}>
          {days.map((date) => {
            const dateStr = date.toLocaleDateString().split("T")[0];
    
            // Filter orders based on selectedPlace AND date
            const filteredOrders = Object.values(data || {}).filter(order => {

              const orderDateAttr = order.customAttributes.find(attr => attr.attributeDefinitionId === customAttributes.produktionsplanung);
              const orderPlaceAttr = order.customAttributes.find(attr => attr.attributeDefinitionId === customAttributes.produktionsplanungOrt);
              const orderPresseAttr = order.customAttributes.find(attr => attr.attributeDefinitionId === customAttributes.produktionsplanungPresse); // Relevant wenn Presse nicht null
    
              if (!orderDateAttr || !orderPlaceAttr) return false; // Ensure attributes exist
    
              const orderDate = new Date(orderDateAttr.dateValue).toLocaleDateString().split("T")[0];
              const orderPlace = orderPlaceAttr.selectedValueId;
              const orderPresse = orderPresseAttr.selectedValueId;
    
              if (selectedPlace == "3935918" && selectedPresse === "all") {
                return orderDate === dateStr && orderPlace === selectedPlace;
              }
               else if (selectedPlace == "3935918") {
                return orderDate === dateStr && orderPlace === selectedPlace && orderPresse === selectedPresse;
              } else {
                return orderDate === dateStr && orderPlace === selectedPlace;
              }
            });
          
            const sortOrders = (orders, customAttributes) => {
              const getCustomAttributeValue = (order, attributeId) => {
                const attribute = order.customAttributes.find(attr => attr.attributeDefinitionId === attributeId);
                if (!attribute) return null;
            
                // Check for the type of value (numberValue, stringValue, booleanValue, dateValue, etc.)
                if (attribute.numberValue) return attribute.numberValue;
                if (attribute.stringValue) return attribute.stringValue;
                if (attribute.booleanValue !== undefined) return attribute.booleanValue;
                if (attribute.dateValue) return new Date(attribute.dateValue).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" }); // Convert to readable date
                return null;
              }; 
              return orders.sort((a, b) => {
                const isShippedA = a.shipped === true;
                const isShippedB = b.shipped === true;
            
                const isProductionFinishedA = getCustomAttributeValue(a, customAttributes.druckAbgeschlossen) === true;
                const isProductionFinishedB = getCustomAttributeValue(b, customAttributes.druckAbgeschlossen) === true;
            
                const picksCompleteA = getCustomAttributeValue(a, customAttributes.productionPicksCompleted) === true;
                const picksCompleteB = getCustomAttributeValue(b, customAttributes.productionPicksCompleted) === true;
            
                const pickFolienA = getCustomAttributeValue(a, customAttributes.pickFolien) === true;
                const pickFolienB = getCustomAttributeValue(b, customAttributes.pickFolien) === true;
            
                const pickTextilienA = getCustomAttributeValue(a, customAttributes.pickTextilien) === true;
                const pickTextilienB = getCustomAttributeValue(b, customAttributes.pickTextilien) === true;
            
                // Assign priority scores (lower means higher priority)
                const getPriority = (isShipped, isFinished, picksComplete, pickFolien, pickTextilien) => {
                  if (isShipped) return 5; // Knallgrün (shipped) → Last
                  if (isFinished) return 4; // Grau (production finished) → Second last
                  if (picksComplete) return 3; // Hellgrün (both available)
                  if (pickFolien || pickTextilien) return 2; // Gelb (partial available)
                  return 1; // Rot (nothing available) → Top priority
                };
            
                const priorityA = getPriority(isShippedA, isProductionFinishedA, picksCompleteA, pickFolienA, pickTextilienA);
                const priorityB = getPriority(isShippedB, isProductionFinishedB, picksCompleteB, pickFolienB, pickTextilienB);
            
                return priorityA - priorityB; // Lower priority value means it should come first
              });
            };
            const sortedOrders = sortOrders(filteredOrders, customAttributes);
    
            return (
              <DroppableDay 
                key={dateStr} 
                day={date} 
                orders={sortedOrders} 
                moveOrder={moveOrder} 
                customerData={customerData}
                salesChannel={salesChannel}
                customAttributes={customAttributes}
                selectedPlace={selectedPlace}
                selectedPresse={selectedPresse}
                presseIsActive={presseIsActive}
                currentUser={currentUser}
                setMessage={setMessage}
                machineId={machineId}
                config={config}
                height={height}
              />
            );
          })}
        </div>
      );
    };

const DroppableDay = ({ day, orders = [], moveOrder, customerData, salesChannel, customAttributes, selectedPlace, selectedPresse, presseIsActive, currentUser, setMessage, machineId, config, height }) => {
    const [, drop] = useDrop({
      accept: ItemType,
      drop: (item) => moveOrder(item.order, day.toISOString().split("T")[0], selectedPlace, selectedPresse), 
    });

    // Calculate Verplante Auftragszeit (Anzahl Drucke x Zeit pro Druck) + (Rüstzeit*Anzahl Aufträge)
    const getConfigValue = (configArray, key) => {
      const configItem = configArray.find(item => item.config_key === key);
      
      if (!configItem) return null; // Return null if key is not found
  
      // Convert the value based on config_type
      switch (configItem.config_type) {
          case "number":
              return Number(configItem.config_value);
          case "bool":
              return configItem.config_value === "true"; // Convert to boolean
          default:
              return configItem.config_value; // Return as string if type is unknown
      }
    };
    const roundToDecimal = (num, decimalPlaces) => {
      const factor = 10 ** decimalPlaces;
      return Math.round(num * factor) / factor;
    };
    const formatGermanNumber = (num, decimalPlaces = 2) => {
      return num.toLocaleString("de-DE", { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });
    };

    // Calculate Anzahl Aufträge
    const orderCount = orders.length;
            
    // Calculate Anzahl Textilien
    const textileCount = orders.reduce((total, order) => {
        const textileAttribute = order.customAttributes.find(attr => attr.attributeDefinitionId === customAttributes.gesamtanzahlTextilien);
        const numberValue = textileAttribute ? parseInt(textileAttribute.numberValue, 10) : 0;
        return total + numberValue;
    }, 0);

    // Calculate Number of Prints
    const calculatePrintCount = (orders) => {
      // Define the 5 specific attributeDefinitionIds
      const specificAttributeIds = [customAttributes.sonderdruck, customAttributes.aermeldruckRechts, customAttributes.aermeldruckLinks, customAttributes.druckHinten, customAttributes.druckVorne];
  
      return orders.reduce((total, order) => {
          const count = order.customAttributes.filter(attr => 
              specificAttributeIds.includes(attr.attributeDefinitionId) && 
              attr.selectedValueId !== "39084" // Count if it's NOT "39084"
          ).length;
          return total + count;
      }, 0);
    };
    const printCount = orderCount > 0 
      ? roundToDecimal(calculatePrintCount(orders) * (textileCount / orderCount),0) 
      : roundToDecimal(calculatePrintCount(orders) * (textileCount) ,0)

    let realTimePerPress = getConfigValue(config, "timePerPress");
    realTimePerPress += getConfigValue(config, "pressAgain") ? getConfigValue(config, "timePerPressNP") : 0;
    realTimePerPress += getConfigValue(config, "countProduct") ? getConfigValue(config, "timePerProduct") : 0;

    const plannedCapacity = roundToDecimal((printCount*(realTimePerPress/60))+((getConfigValue(config, "setupTime")/60)*orderCount),2);
    const formattedPlannedCapacity = formatGermanNumber(plannedCapacity,2);

    // Calculate Capacity for the Day
    const [totalCapacity, setTotalCapacity] = useState(0);
    useEffect(() => {
      if (selectedPlace != "3935918") return;
      const fetchCapacity = async () => {
        try {
          const response = await axios.post(`${API}/shirtival/productionPlaningCapacity.php`, {
            methode: 'GET',
            data: day,
            machineId: machineId,
            currentUser
          });
          setTotalCapacity(response?.data || 0);
        } catch (error) {
          setMessage(error.response?.data?.detail || error.message);
          console.error("Error fetching calendar data:", error);
        } 
      };
      fetchCapacity();
    }, [day]);
    const formattedTotalCapacity = formatGermanNumber(totalCapacity,2);

    // Berechne Auslastung
    const calculatePercentage = (part, total) => {
      if (total === 0) return 0; // Avoid division by zero
      return (part / total) * 100;
    };
    const [utilization, setUtilization] = useState(0);
    useEffect(() => {
      let newUtilization = 0;
      if (totalCapacity !== 0) {
        newUtilization = roundToDecimal(calculatePercentage(plannedCapacity, totalCapacity), 2);
      } else {
        newUtilization = roundToDecimal(calculatePercentage(plannedCapacity, 0.1), 2);
      }
      setUtilization(newUtilization);
    }, [plannedCapacity, totalCapacity]); // Dependencies
    const formattedUtilization = formatGermanNumber(utilization, 2);

    // Berechne Freie Kapazität
    const freeCapacity = totalCapacity-plannedCapacity;
    const formattedFreeCapacity = formatGermanNumber(freeCapacity,2);

    // Berechne Puffer
    const formattedBufferTime = formatGermanNumber(roundToDecimal((getConfigValue(config, "bufferTime")/60),2),2);

    return (
      <div>
        <div>
          {/* Display date and day name */}
          <div className="w3-border w3-center">
            <strong>{day.toLocaleDateString("de-DE", { weekday: "long"})}</strong> 
            <br/>{day.toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" })}
          </div>
          {/* Display Datainformation */}
          <div className="w3-border w3-margin-top">
              <ul className="w3-ul">
                  {selectedPlace === "3935918" ? (
                  <>
                  <li className="w3-display-container w3-border-0" style={{padding: '0px 4px'}}>
                    <div className="w3-bar">
                      <div className="w3-bar-item" style={{padding: '0px 0px'}}>Gesamtkapazität:</div>  
                      <div className="w3-bar-item w3-right" style={{padding: '0px 0px'}}>{formattedTotalCapacity} h</div>  
                    </div>
                  </li>
                  <li className="w3-display-container w3-border-0" style={{padding: '0px 4px'}}>
                    <div className="w3-bar">
                      <div className="w3-bar-item" style={{padding: '0px 0px'}}>Verplante Kapazität:</div>  
                      <div className="w3-bar-item w3-right" style={{padding: '0px 0px'}}>{formattedPlannedCapacity} h</div>  
                    </div>
                  </li>
                  <li className="w3-display-container w3-border-0" style={{padding: '0px 4px'}}>
                    <div className="w3-bar">
                      <div className="w3-bar-item" style={{padding: '0px 0px'}}>Auslastung:</div>  
                      <div className="w3-bar-item w3-right" style={{padding: '0px 0px'}}>{formattedUtilization} %</div>  
                    </div>
                  </li>
                  <li className="w3-display-container w3-border-0" style={{padding: '0px 4px'}}>
                    <div className="w3-bar">
                      <div className="w3-bar-item" style={{padding: '0px 0px'}}>Freie Kapazität:</div>  
                      <div className="w3-bar-item w3-right" style={{padding: '0px 0px'}}>{formattedFreeCapacity} h</div>  
                    </div>
                  </li>
                  <li className="w3-display-container w3-border-0" style={{padding: '0px 4px'}}>
                    <div className="w3-bar">
                      <div className="w3-bar-item" style={{padding: '0px 0px'}}>Puffer:</div>  
                      <div className="w3-bar-item w3-right" style={{padding: '0px 0px'}}>{formattedBufferTime} h</div>  
                    </div>
                  </li>
                  </>
                  ) : null }
                  <li className="w3-display-container w3-border-0" style={{padding: '0px 4px'}}>
                    <div className="w3-bar">
                      <div className="w3-bar-item" style={{padding: '0px 0px'}}>Anzahl Aufträge:</div>  
                      <div className="w3-bar-item w3-right" style={{padding: '0px 0px'}}>{orderCount}</div>  
                    </div>
                  </li>
                  <li className="w3-display-container w3-border-0" style={{padding: '0px 4px'}}>
                    <div className="w3-bar">
                      <div className="w3-bar-item" style={{padding: '0px 0px'}}>Anzahl Textilien:</div>  
                      <div className="w3-bar-item w3-right" style={{padding: '0px 0px'}}>{textileCount}</div>  
                    </div>
                  </li>
                  <li className="w3-display-container w3-border-0" style={{padding: '0px 4px'}}>
                    <div className="w3-bar">
                      <div className="w3-bar-item" style={{padding: '0px 0px'}}>Anzahl Drucke:</div>  
                      <div className="w3-bar-item w3-right" style={{padding: '0px 0px'}}>{printCount}</div>  
                    </div>
                  </li>
              </ul>
          </div>
        </div>
        <div ref={drop} className={`min-h-[500px] w3-border w3-margin-top w3-center flex flex-col`}>
        {orders.length > 0 ? (
          <React.Fragment>
            {orders.map((order) => (
              <DraggableOrder key={order.id} order={order} moveOrder={() => moveOrder(order, null, selectedPlace, selectedPresse)} customerData={customerData} salesChannel={salesChannel} customAttributes={customAttributes} presseIsActive={presseIsActive} config={config} />
            ))}
            <StaticOrder orders={orders} moveOrder={() => null} customAttributes={customAttributes} config={config} className={"bg-blue-200 w3-tiny"} name={"Freie Kapazität"} display={"freeCapacity"} freeCapacity={freeCapacity} />
            <StaticOrder orders={orders} moveOrder={() => null} customAttributes={customAttributes} config={config} className={"bg-blue-400 w3-tiny"} name={"Puffer"} display={"bufferTime"} />
          </React.Fragment>
        ) : (
          <p className="text-gray-500 w3-small w3-center">Für diesen Tag wurden noch keine Aufträge geplant</p> // Display message when no orders
        )}
        </div>
      </div>
    );
  };

const DraggableOrder = ({ order, moveOrder, customerData, salesChannel, customAttributes, presseIsActive, config }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { order },
    canDrag: () => presseIsActive,  // Only allow dragging if presse is active
    collect: (monitor) => ({ isDragging: presseIsActive && monitor.isDragging() }),
  });

  const getPartyName = (key) => {
    if (Object.keys(customerData).length > 0) {
        // Convert the object values to an array
        const parties = Object.values(customerData);
        const party = parties.find(party => party.id === key);
        
        // Return the desired string based on conditions
        return party 
            ? (party.lastName ? `${party.firstName} ${party.lastName}` : party.company) 
            : key; // Return the key if no party is found
    }
    
    // If partyData is empty, just return the key
    return key;
  };

  // Calculate Zeit (Anzahl Drucke x Zeit pro Druck) + (Rüstzeit)
  const calculatePrintCount = (order) => {
    if (!order || !Array.isArray(order.customAttributes)) return 0; // Ensure we have a valid array
    const specificAttributeIds = [
      customAttributes.sonderdruck,
      customAttributes.aermeldruckRechts,
      customAttributes.aermeldruckLinks,
      customAttributes.druckHinten,
      customAttributes.druckVorne
    ];
    return order.customAttributes.filter(attr => 
      specificAttributeIds.includes(attr.attributeDefinitionId) && 
      attr.selectedValueId !== "39084" // Count only if selectedValueId is NOT "39084"
    ).length;
  };
  const getConfigValue = (configArray, key) => {
    const configItem = configArray.find(item => item.config_key === key);
    if (!configItem) return null; // Return null if key is not found
    // Convert the value based on config_type
    switch (configItem.config_type) {
        case "number":
            return Number(configItem.config_value);
        case "bool":
            return configItem.config_value === "true"; // Convert to boolean
        default:
            return configItem.config_value; // Return as string if type is unknown
    }
  };
  const roundToDecimal = (num, decimalPlaces) => {
    const factor = 10 ** decimalPlaces;
    return Math.round(num * factor) / factor;
  };
  const formatGermanNumber = (num, decimalPlaces = 2) => {
    return num.toLocaleString("de-DE", { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });
  };

  const getSalesChannelName = (key) => {
    if (Object.keys(salesChannel).length > 0) {
      const salesChannels = Object.values(salesChannel);
      const channel = salesChannels.find(channel => channel.key === key);
      return channel ? channel.name : key; // Return name if found, otherwise return key itself
    }
    return key;
  };

  const getCustomAttributeValue = (order, attributeId) => {
    const attribute = order.customAttributes.find(attr => attr.attributeDefinitionId === attributeId);
    if (!attribute) return null;

    // Check for the type of value (numberValue, stringValue, booleanValue, dateValue, etc.)
    if (attribute.numberValue) return attribute.numberValue;
    if (attribute.stringValue) return attribute.stringValue;
    if (attribute.booleanValue !== undefined) return attribute.booleanValue;
    if (attribute.dateValue) return new Date(attribute.dateValue).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" }); // Convert to readable date
    return null;
  };  

  const getClassForOrder = (order) => {
    // i. Auftrag ist hellgrün: Textilien und Folie vorhanden
    // ii. Auftrag ist gelb: Textilien oder Folie fehlt oder Teillieferung Textilien
    // iii. Auftrag ist rot: Nichts ist vorhanden
    // iv. Auftrag ist grau: Produktion abgeschlossen
    // v. Auftrag ist knallgrün: Auftrag ist versendet
    // vi. Sortierung der Farben (erledigte Aufträge nach ganz unten, gelb und rot nach ganz oben)
    const shipped = order.shipped === true;
    const productionFinished = getCustomAttributeValue(order, customAttributes.druckAbgeschlossen) === true;
    const picksComplete = getCustomAttributeValue(order, customAttributes.productionPicksCompleted) === true;
    const pickFolien = getCustomAttributeValue(order, customAttributes.pickFolien) === true;
    const pickTextilien = getCustomAttributeValue(order, customAttributes.pickTextilien) === true;
    let className = "";

    // v. Auftrag ist knallgrün: Auftrag ist versendet
    if (shipped) {
        className = "bg-lime-700";
    }
    // iv. Auftrag ist grau: Produktion abgeschlossen
    if (!shipped && productionFinished) {
        className = "bg-gray-500";
    }
    // iii. Auftrag ist rot: Nichts ist vorhanden
    if (!shipped && !productionFinished && !pickFolien && !pickTextilien) {
        className = "bg-red-600";
    }
    // ii. Auftrag ist gelb: Textilien oder Folie fehlt oder Teillieferung Textilien
    if (!shipped && !productionFinished && (pickFolien || pickTextilien)) {
        className = "bg-yellow-300";
    }
    // i. Auftrag ist hellgrün: Textilien und Folie vorhanden
    if (!shipped && !productionFinished && picksComplete) {
        className = "bg-lime-500";
    }

    // Add more conditions and classes as needed
    return className;
  };

  let realTimePerPress = getConfigValue(config, "timePerPress");
  realTimePerPress += getConfigValue(config, "pressAgain") ? getConfigValue(config, "timePerPressNP") : 0;
  realTimePerPress += getConfigValue(config, "countProduct") ? getConfigValue(config, "timePerProduct") : 0;

  const textileCount = Number(getCustomAttributeValue(order, customAttributes.gesamtanzahlTextilien));
  const printCount = calculatePrintCount(order)*textileCount;
  const time = roundToDecimal((printCount*(realTimePerPress/60))+((getConfigValue(config, "setupTime")/60)),2);
  const formattedTime = formatGermanNumber(time,2);

  return (
    <div
      ref={drag}
      className={`w3-border w3-padding w3-small ${getClassForOrder(order)} cursor-move ${
        isDragging ? "opacity-50" : ""
      }`}
    >
      {/* Display order */}
      {order.orderNumber} - {getPartyName(order.customerId)}<br/>
      {getCustomAttributeValue(order, customAttributes.gesamtanzahlTextilien)} Teile - {formattedTime} h<br/>
      Deadline: {getCustomAttributeValue(order, customAttributes.deadline)}<br/>
      Deadline Kunde: {getCustomAttributeValue(order, customAttributes.deadlineKunde)}<br/>
      {/* Vertriebsweg: {getSalesChannelName(order.salesChannel)}<br/> */}
    </div>
  );
};

const StaticOrder = ({ orders, moveOrder, customAttributes, config, className, name, display, freeCapacity }) => {
  const getConfigValue = (configArray, key) => {
    const configItem = configArray.find(item => item.config_key === key);
    if (!configItem) return null; // Return null if key is not found
    // Convert the value based on config_type
    switch (configItem.config_type) {
        case "number":
            return Number(configItem.config_value);
        case "bool":
            return configItem.config_value === "true"; // Convert to boolean
        default:
            return configItem.config_value; // Return as string if type is unknown
    }
  };
  const roundToDecimal = (num, decimalPlaces) => {
    const factor = 10 ** decimalPlaces;
    return Math.round(num * factor) / factor;
  };
  const formatGermanNumber = (num, decimalPlaces = 2) => {
    return num.toLocaleString("de-DE", { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });
  };
  const getCustomAttributeValue = (order, attributeId) => {
    const attribute = order.customAttributes.find(attr => attr.attributeDefinitionId === attributeId);
    if (!attribute) return null;

    // Check for the type of value (numberValue, stringValue, booleanValue, dateValue, etc.)
    if (attribute.numberValue) return attribute.numberValue;
    if (attribute.stringValue) return attribute.stringValue;
    if (attribute.booleanValue !== undefined) return attribute.booleanValue;
    if (attribute.dateValue) return new Date(attribute.dateValue).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" }); // Convert to readable date
    return null;
  };  

  const [displayedValue, setDisplayedValue] = useState("0");
  const [height, setHeight] = useState(0);
  useEffect(() => {
    let newdisplayedValue = "0";
    if (display === "freeCapacity") {
      newdisplayedValue = formatGermanNumber(roundToDecimal(freeCapacity,2),2);
      setHeight(10);
    } else {
      newdisplayedValue = formatGermanNumber(roundToDecimal(getConfigValue(config, display)/60,2),2);
    }
    setDisplayedValue(newdisplayedValue);
  }, [display, config, freeCapacity]); // Dependencies

  if (display === "freeCapacity" && freeCapacity <= 0) {
    return <div></div>;
  }

  return (
    <div
      className={`w3-border w3-padding ${className} ${display === "freeCapacity" ? "flex flex-grow justify-center items-center" : ""}`}
    >
      {/* Display order */}
      <div>{name}<br/>
      {displayedValue} h</div>
    </div>
  );
};

// Select component from Headless UI
const Select = ({ value, onChange, options, standard }) => {
    // Find the selected option by ID to display its label
    const selectedOption = options.find(option => option.id === value);
  
    return (
      <Menu as="div" className="">
        <MenuButton className="w3-quarter w3-input relative">
          {selectedOption ? selectedOption.value : standard}
          <i className="fa fa-angle-down w3-large w3-margin-left w3-right"></i>
        </MenuButton>
        <MenuItems className="absolute bg-white border border-gray-300 rounded-md shadow-lg mt-1 z-10 w3-half">
          {options.map(option => (
            <MenuItem
              key={option.id}
              as="div"
              onClick={() => onChange(option.id)}  
              className="text-left p-2 hover:bg-gray-100 cursor-pointer"
            >
              {option.value}
            </MenuItem>
          ))}
        </MenuItems>
      </Menu>
    );
  };
  
export { ShirtivalProductionPlan };