import React, { useState, useEffect } from 'react';
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { Modal } from "../../utility/modal";
import { Menu, MenuButton, MenuItems, MenuItem, Input } from "@headlessui/react";

const TimeManagementDashboard = ({ currentUser, year, setYear }) => {
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [message, setMessage] = useState("");

    const [yearsOptions, setYearsOptions] = useState([]);

    useEffect(() => {
        setLoadingInitial(true)

        const fetchYearsOptions = async () => {
            try {               
                const testData = [
                    2025,
                    2024,
                    2023
                ];
                const yearsOptions = [...testData].sort((a, b) => b - a);
            } catch (error) {
                setMessage(error.response?.data?.detail || error.message);
            } finally {
                setLoadingInitial(false)
            }
        };
    
        fetchYearsOptions();
    }, []);


    const Select = ({ value, onChange, options, standard, className }) => {
        // Find the selected option by ID to display its label
        const selectedOption = options.find(option => option.id === value);
      
        return (
            <Menu as="div" className="w-full">
                {({ open }) => (
                    <>
                        <MenuButton className={`${className}`}>
                            <span className='w3-left'>{selectedOption ? selectedOption.value : standard}</span>
                            <i className={`fa fa-angle-down transition-transform ${open ? 'rotate-180' : ''}`}></i>
                        </MenuButton>
                        <MenuItems className="absolute mt-1 w-fit bg-white border border-gray-300 rounded-md shadow-lg z-10">
                            {options.length > 0 ? (
                                options.map(option => (
                                    <MenuItem
                                        key={option}
                                        as="div"
                                        onClick={() => onChange(option)}
                                        className="p-2 hover:bg-gray-100 cursor-pointer"
                                    >
                                        {option}
                                    </MenuItem>
                                ))
                            ) : (
                                <div className="p-2 text-gray-500">Keine Optionen</div>
                            )}
                        </MenuItems>
                    </>
                )}
            </Menu>
        );
    };

    return (
        <DndProvider backend={HTML5Backend}>
            {/* Show Modal with the Error */}
            {message && <Modal message={message} setMessage={setMessage} />}
            {/* Show "Loading..." when loadingInitial is true */}
            {loadingInitial ? (
            <div className='w3-center'><i className='fa fa-spinner fa-spin w3-large w3-margin-top'></i></div>
            ) : (
            <React.Fragment>
                <div className="w3-section w3-mobile w3-bar">
                    <Select 
                        value={year} 
                        onChange={(newValue) => {
                            setYear(newValue);
                        }} 
                        options={yearsOptions} 
                        standard={"Wähle Jahr aus."}
                        className="w-full bg-transparent"
                        onBlur={() => setYear(year)}
                    />
                </div>
                <div className="w3-section w3-mobile w3-bar">
                    <div className="w3-half" style={{paddingRight: '8px'}}>
                        <h3>Überstunden Gesamt</h3>
                        <div>
                            <table className="w3-table" style={{ tableLayout: 'auto' }}>
                                <thead>
                                    <tr className='w3-light-grey'>
                                        <th className="bg-white w-1/3"></th>
                                        <th className="w-1/3 w3-border border-2 !text-center">Arbeitszeit</th>
                                        <th className="w-1/3 w3-border !text-center">Überstunden</th>
                                    </tr>
                                    <tr className='w3-light-grey'>
                                        <th className="w-1/3 w3-border">Monat</th>
                                        <th className="w-1/3 w3-border !text-center font-normal">Stunden</th>
                                        <th className="w-1/3 w3-border !text-center font-normal">Stunden</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='w3-light-grey'>
                                        <th className="w-1/3 w3-border">Vorjahr</th>
                                        <th className="bg-white w-1/3 w3-border !text-center font-normal"></th>
                                        <th className="bg-white w-1/3 w3-border !text-center font-normal"></th>
                                    </tr>
                                    <tr className='w3-light-grey'>
                                        <th className="w-1/3 w3-border"></th>
                                        <th className="bg-white w-1/3 w3-border !text-center font-normal text-white">&nbsp;</th>
                                        <th className="bg-white w-1/3 w3-border !text-center font-normal"></th>
                                    </tr>
                                    {Array.from({ length: 12 }, (_, i) => {
                                        const monthName = new Date(0, i).toLocaleString('de-DE', { month: 'long' });
                                        return (
                                            <tr key={i} className='w3-light-grey'>
                                                <th className="w-1/3 w3-border">{monthName.charAt(0).toUpperCase() + monthName.slice(1)}</th>
                                                <th className="bg-white w-1/3 w3-border !text-center font-normal"></th>
                                                <th className="bg-white w-1/3 w3-border !text-center font-normal"></th>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr className='w3-light-grey'>
                                        <th className="bg-white w-1/3"></th>
                                        <th className="bg-white w-1/3 w3-border border-2 !text-center font-normal">0,00</th>
                                        <th className="bg-white w-1/3 w3-border border-2 !text-center font-normal">0,00</th>
                                    </tr>
                                    <tr className='w3-light-grey'>
                                        <th className="bg-white w-1/3"></th>
                                        <th className="w-1/3 w3-border border-2 !text-center" colSpan={2}>0,00</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className='pt-4'>
                            <table className="w3-table" style={{ tableLayout: 'auto' }}>
                                <tbody>
                                    <tr className='w3-light-grey'>
                                        <th className="w-1/3 w3-border">ÜS-Wert (€)</th>
                                        <th className="bg-white w-1/3 w3-border !text-right font-normal"></th>
                                        <th className="bg-white w-1/3 w3-border !text-right font-normal"></th>
                                    </tr>
                                    <tr className='w3-light-grey'>
                                        <th className="w-1/3 w3-border">ÜS "ausgegeben" (€)</th>
                                        <th className="bg-white w-1/3 w3-border !text-right font-normal"></th>
                                        <th className="bg-white w-1/3 w3-border !text-right font-normal"></th>
                                    </tr>
                                    <tr className='w3-light-grey'>
                                        <th className="bg-white w-1/3"></th>
                                        <th className="w-1/3 w3-border border-2 !text-right" colSpan={2}>0,00</th>
                                    </tr>
                                </tbody>                            
                            </table>
                        </div>
                        <div className='pt-4'>
                            <table className="w3-table" style={{ tableLayout: 'auto' }}>
                                <tbody>
                                    <tr className='w3-light-grey'>
                                        <th className="w-1/3 w3-border">Restl. ÜS</th>
                                        <th className="bg-white w-2/3 w3-border !text-right font-normal">0,00</th>
                                    </tr>                                 
                                </tbody>                            
                            </table>
                        </div>
                    </div>
                    <div className="w3-half" style={{paddingLeft: '8px'}}>
                        <div>
                            <h3>Urlaub</h3>
                            <table className="w3-table-all w3-bordered w3-border" style={{ tableLayout: 'auto' }}>
                                <tbody>
                                    <tr className='w3-light-grey'>
                                        <th className='w-1/2 w3-border'>Urlaubsanspruch:</th>
                                        <th className='w-1/2 w3-border bg-white font-normal !text-right'>0,00</th>
                                    </tr>
                                    <tr className='w3-light-grey'>
                                        <th className='w-1/2 w3-border'>Urlaubsanspruch ohne ÜS:</th>
                                        <th className='w-1/2 w3-border bg-white font-normal !text-right'>0,00</th>
                                    </tr>
                                    <tr className='w3-light-grey'>
                                        <th className='w-1/2 w3-border'>Urlaubsanspruch inkl. ÜS:</th>
                                        <th className='w-1/2 w3-border bg-white font-normal !text-right'>0,00</th>
                                    </tr>
                                    <tr className='w3-light-grey'>
                                        <th className='w-1/2 w3-border'>Genommener Urlaub:</th>
                                        <th className='w-1/2 w3-border bg-white font-normal !text-right'>0,00</th>
                                    </tr>
                                    <tr className='w3-light-grey'>
                                        <th className='w-1/2 w3-border'>Verbleibender Urlaub:</th>
                                        <th className='w-1/2 w3-border bg-white font-normal !text-right'>0,00</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='pt-4'>
                            <h3>Krankheit</h3>
                            <table className="w3-table-all w3-bordered w3-border" style={{ tableLayout: 'auto' }}>
                                <tbody>
                                    <tr className='w3-light-grey'>
                                        <th className='w-1/2 w3-border'>Gesamte Tage:</th>
                                        <th className='w-1/2 w3-border bg-white font-normal !text-right'>0,00</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
            )}
        </DndProvider>
    );
};

export {TimeManagementDashboard};