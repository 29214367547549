import React, { useState, useEffect } from 'react';
import { Menu, MenuButton, MenuItems, MenuItem, Input } from "@headlessui/react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { Modal } from "../../utility/modal";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { de } from 'date-fns/locale';

const Absences = ({ currentUser, year, setYear, placeOfWork, setPlaceOfWork, fetchFeiertage }) => {
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [message, setMessage] = useState("");
    const [hasChanges, setHasChanges] = useState(false);

    const [dates, setDates] = useState({vacation: [], sickness: []});
    const [initialDates, setInitialDates] = useState({vacation: [], sickness: []});

    const [hiddenDaysVacation, setHiddenDaysVacation] = useState([]);
    const [selectedYearVacation, setSelectedYearVacation] = useState(new Date().getFullYear());
    useEffect(() => {
        fetchFeiertage(selectedYearVacation, placeOfWork).then(setHiddenDaysVacation);
    }, [selectedYearVacation, placeOfWork]);

    const [hiddenDaysSickness, setHiddenDaysSickness] = useState([]);
    const [selectedYearSickness, setSelectedYearSickness] = useState(new Date().getFullYear());
    useEffect(() => {
        fetchFeiertage(selectedYearSickness, placeOfWork).then(setHiddenDaysSickness);
    }, [selectedYearSickness, placeOfWork]);

    const resetChanges = () => {
        setLoading(true);

        setDates(initialDates);
        setLoading(false);
    };

    const saveChanges = () => {
        setLoading(true);
        
        setInitialDates(dates);
        setLoading(false);
    };

    useEffect(() => {
        setHasChanges(JSON.stringify(dates) !== JSON.stringify(initialDates))
    }, [dates,initialDates]);

    const handleDateSelect = (type, selectedDates) => {
        if (selectedDates) {
            const timestamps = selectedDates.map(date => date.getTime()); // Convert to Unix timestamp
            setDates(prev => ({ ...prev, [type]: timestamps }));
        }
    };

    useEffect(() => {
        setLoadingInitial(true)

        if (placeOfWork == null) {
            setPlaceOfWork('SH')
        }
        // Abrufen der gespeicherten Daten!
        // setDates und initial
        setLoadingInitial(false)
    }, []);

    return (
        <DndProvider backend={HTML5Backend}>
            {/* Show Modal with the Error */}
            {message && <Modal message={message} setMessage={setMessage} />}
            {/* Show "Loading..." when loadingInitial is true */}
            {loadingInitial ? (
            <div className='w3-center'><i className='fa fa-spinner fa-spin w3-large w3-margin-top'></i></div>
            ) : (
            <React.Fragment>
                {hasChanges &&
                    <div className="w3-section w3-mobile w3-bar">
                        <button
                            className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`}
                            style={{ pointerEvents: loading ? 'none' : 'auto' }}
                            onClick={() => saveChanges()}
                        >
                            {!loading ? (
                                <>
                                    <i className='fa fa-save w3-margin-right w3-small'></i>
                                </>
                            ) : (
                                <>
                                    <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                                </>
                            )}
                            Speichern
                        </button>
                        <button
                            className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right`}
                            style={{ pointerEvents: loading ? 'none' : 'auto', marginRight: '8px' }}
                            onClick={() => resetChanges()}
                        >
                            {!loading ? (
                                <>
                                    <i className='fa fa-save w3-margin-right w3-small'></i>
                                </>
                            ) : (
                                <>
                                    <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                                </>
                            )}
                            Zurücksetzen
                        </button> 
                    </div>
                }
                <div className="w3-section w3-mobile w3-bar">
                    {/* Urlaub (Vacation) */}
                    <div className="w3-half" style={{paddingRight: '8px'}}>
                        <h3>Urlaub</h3>
                        <DayPicker
                            mode="multiple"
                            selected={dates.vacation.map(timestamp => new Date(timestamp))}
                            onSelect={(selected) => handleDateSelect("vacation", selected)}
                            captionLayout="dropdown"
                            locale={de}
                            defaultMonth={new Date()}
                            startMonth={new Date(new Date().getFullYear() - 2, 0)} // 2 years in the past
                            endMonth={new Date(new Date().getFullYear() + 2, 11)} // 2 years in the future
                            onMonthChange={(date) => setSelectedYearVacation(date.getFullYear())}  // Updates when navigating
                            hidden={hiddenDaysVacation}
                            disabled={{dayOfWeek:[0, 6]}} // Disable weekends
                            classNames={{
                                today: `w3-text-theme`, // Style for today's date
                                chevron: `fill-[#b42733] hover:fill-[#911d28]`, // Chevron navigation color
                                selected: `bg-[#b42733] text-white`, // Selected date
                                range_start: `bg-[#b42733] text-white rounded-l-full`, // Start of range
                                range_end: `bg-[#b42733] text-white rounded-r-full`, // End of range
                                range_middle: `bg-[#f5cfd2] text-[#b42733]`, // Middle of the range
                            }}
                        />                
                    </div>
                    {/* Krankheit (Sickness) */}
                    <div className="w3-half" style={{paddingLeft: '8px'}}>
                        <h3>Krankheit</h3>
                        <DayPicker
                            mode="multiple"
                            selected={dates.sickness.map(timestamp => new Date(timestamp))}
                            onSelect={(selected) => handleDateSelect("sickness", selected)}
                            captionLayout="dropdown"
                            locale={de}
                            defaultMonth={new Date()}
                            startMonth={new Date(new Date().getFullYear() - 2, 0)} // 2 years in the past
                            endMonth={new Date(new Date().getFullYear() + 2, 11)} // 2 years in the future
                            onMonthChange={(date) => setSelectedYearSickness(date.getFullYear())}  // Updates when navigating
                            hidden={hiddenDaysSickness}
                            disabled={{dayOfWeek:[0, 6]}} // Disable weekends
                            classNames={{
                                today: `w3-text-theme`, // Style for today's date
                                chevron: `fill-[#b42733] hover:fill-[#911d28]`, // Chevron navigation color
                                selected: `bg-[#b42733] text-white`, // Selected date
                                range_start: `bg-[#b42733] text-white rounded-l-full`, // Start of range
                                range_end: `bg-[#b42733] text-white rounded-r-full`, // End of range
                                range_middle: `bg-[#f5cfd2] text-[#b42733]`, // Middle of the range
                            }}
                        />  
                    </div>   
                </div>
            </React.Fragment>
            )}
        </DndProvider>
    );
};

export {Absences};