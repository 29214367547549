import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ResetPassword from './components/passwordreset';

import "./index.css";
import "./css/w3.css";
import "./css/w3-theme-private.css";
import "./css/font-awesome.min.css";
import "./css/icomoon.css";

import App from './components/app.jsx';

const container = document.getElementById('root');
const root = createRoot(container)

root.render(
    <BrowserRouter>
        <Routes>
            <Route exact path="/resetpassword" element={<ResetPassword />} />
            <Route path="*" element={<App />} />
        </Routes>
    </BrowserRouter>,
);