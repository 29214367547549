import React, { useState } from 'react';

const TimeManagement = ({ currentUser, year, month, setYear, setMonth }) => {
    
    return (
      null
    );
};

export {TimeManagement};
